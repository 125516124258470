.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    height: 100vh;
    background-color: #f0f0f0;
}

.chatbox {
    width: 60%;
    max-width: 600px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 400px;
    margin-top: 20px;
    overflow: hidden;
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.chat-message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.chat-message p {
    margin: 0 10px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f1f1f1;
}

.chat-message.user {
    justify-content: flex-end;
}

.chat-message.bot {
    justify-content: flex-start;
}

.typing-indicator {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #888;
    border-radius: 50%;
    margin-right: 4px;
    animation: blink 1s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

.user-icon, .bot-icon {
    width: 30px;  /* Adjust the size as needed */
    height: 30px;  /* Adjust the size as needed */
}

.user-icon {
    margin-left: 10px;  /* Space between message and user icon */
}

.bot-icon {
    margin-right: 10px;  /* Space between message and bot icon */
}

.chat-input {
    display: flex;
    border-top: 1px solid #ddd;
    padding: 10px;
    align-items: center;
}

.chat-input textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
    font-size: 16px;
}

.chat-input button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.chat-input button:hover {
    background-color: #0056b3;
}
